import { SagaRegistry } from "../SagaRegistry";
import { requestHandler, setAlertAndLoading, CLEAR_ALERT } from "../common";
import { takeEvery, put } from 'redux-saga/effects';
import {
  LOGIN, LOGIN_SUCCESS, LOGOUT, VALIDATE_TOKEN_SUCCESS, LOGOUT_SUCCESS
} from "./actionsType";
import { axios } from "../../utils";
import { KEY_NM_API, KEY_NM_GUID, VALIDATE_TOKEN, AUDIT_NM_API } from "../common";

const USER_PATH = '';

const getNewNMIKEYObject = (longSessionToken) => {
  let sessionObj = {};
  if (longSessionToken && longSessionToken.trim().length > 0) {
    longSessionToken = longSessionToken.trim();
    sessionObj = window.atob(longSessionToken);
    sessionObj = JSON.parse(sessionObj);
  }
  return sessionObj;
}
function* logInUser({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    const body = { api: true, ent_usr: payload };
    const res = yield axios.post(USER_PATH + '/login', body);
    if (res) {
      if (res.current_ent_usr) {
        localStorage.setItem(KEY_NM_API, res.current_ent_usr.api_key)
        if (res.ent_org_logo) {
          res.current_ent_usr.logo_url = res.ent_org_logo;
        }
      }
    }
    yield setAlertAndLoading(false, { message: 'Login Successfull' });
    yield put({ type: LOGIN_SUCCESS, payload: res })
  })
}
function* validateToken({ payload }) {
  yield requestHandler(function* () {
    try {
      let NMKey, sessionObj, user = 'Error';
      let token = payload && payload.token;
      if (!token) {
        token = localStorage.getItem(AUDIT_NM_API);
      }
      if (token) {
        sessionObj = getNewNMIKEYObject(token);
        NMKey = sessionObj[KEY_NM_API];
      }
      if (NMKey) {
        const header = { [KEY_NM_API]: NMKey };
        const res = yield axios.post('/tasks/api_user_detail', { task: { api_key: NMKey } }, header);
        user = res.errors ? 'Error' : res;
        localStorage.setItem(KEY_NM_API, NMKey)
      }
      if (user !== 'Error' && token) {
        user.session_token = token;
        localStorage.setItem(AUDIT_NM_API, token)
      }
      yield put({ type: VALIDATE_TOKEN_SUCCESS, payload: user })
    } catch (error) {
      localStorage.setItem(KEY_NM_API, null)
      localStorage.setItem(KEY_NM_GUID, null)
      yield put({ type: VALIDATE_TOKEN_SUCCESS, payload: 'Error' })
      throw error;
    }
  })
}
function* logout() {
  yield requestHandler(function* () {
    try {
      yield axios.post('/consoles/logout_api_user', {});
    } catch (error) {
      console.error("Logout Failed", error)
    }
    localStorage.setItem(KEY_NM_API, null)
    localStorage.setItem(KEY_NM_GUID, null)
    yield setAlertAndLoading(false, { message: 'Logged out Successfully' });
    yield put({ type: LOGOUT_SUCCESS });
    yield put({ type: CLEAR_ALERT });
  })
}
SagaRegistry.register(function* () {
  yield takeEvery(LOGIN, logInUser)
  yield takeEvery(VALIDATE_TOKEN, validateToken)
  yield takeEvery(LOGOUT, logout)
})