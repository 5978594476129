import React from 'react';
import MuiButton from '@mui/material/Button';
import MuiIconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';

export const LinkButton = (props) => {
  const { type, className, label, ...rest } = props;
  return (
    <button type={type || 'button'} className={`link-btn ${className || ''} ${rest.disabled && 'disabled'}`} {...rest}>
      <span>{label}</span>
    </button>
  )
}
export const AddButton = (props) => {
  const { text, type, className, ...rest } = props
  const showIcon = props.showIcon !== undefined ? props.showIcon : true;
  return (
    <MuiButton type={type || 'button'} classes={{ root: 'fam-robo-med' }} className={`add-btn ${className || ''}`} variant="contained" {...rest}>
      <span className='btn-text h5'>{text}</span>
      {showIcon && <i className='icon-plus' />}
    </MuiButton>
  )
}
export const Button = (props) => {
  const { text, type, color, className, loading, ...rest } = props
  const buttonProps = {
    type: type || 'button',
    className: `h4 fam-robo-med app-btn ${loading && 'btn-load'} ${className || ''}`,
    style: { backgroundColor: color || '#707070' },
    variant: "contained",
    ...rest
  }
  return (
    <>
      <LoadingButton
        loading={loading || false}
        loadingPosition="end"
        variant="outlined" {...buttonProps}>
        <span className='btn-text'>{text}{loading ? 'ing' : ''}</span>
      </LoadingButton>
    </>
  )
}
export const IconButton = (props) => {
  return (
    <MuiIconButton {...props}>
      {props.children}
    </MuiIconButton>
  )
}
