import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from "react-redux";
import { Breadcrumbs, Link, CircularProgress } from '@mui/material';
import {
  fetchTaskDepartment, getSourceWithTaskById, fetchTaskAccoutable, fetchTaskAssignee, fetchTaskSupervisor,
  fetchTaskRepeationOccurList, fetchDashBoard, isListReady, fetchMappedOrgs,
} from "../../store";

const TaskHomeScreen = (props) => {
  const { isListReady } = props
  const location = useLocation();
  const [source, setSource] = useState({ label: 'All' });
  const [paths, setPaths] = useState([]);
  useEffect(() => {
    if (isListReady) {
      let urlObj = new URLSearchParams(window.location.search);
      let asmt = urlObj.get('asmt_id')
      if (asmt) {
        asmt = props.getSource(asmt);
      }
      setSource(asmt || { label: 'All' });
      let ps = [];
      location.pathname.split('/').forEach((p, i) => {
        if (p && p !== 'T') {
          ps[i] = { endpoint: p, label: p.replaceAll('-', ' ') }
        }
      })
      setPaths(ps)
    }
  }, [location, isListReady])
  useEffect(() => {
    props.fetchDefinedList();
  }, []);
  return (
    <div className='col full main task-home'>
      {
        props.isListReady ?
          <>
            {/* <Breadcrumbs aria-label="breadcrumb" classes={{ li: 'h7 exo2 breadcrumb' }}>
              <Link underline="none" classes={{ root: 'link' }} color="#1177CC" href={"/T" + window.location.search} >Tracker</Link>
              <Link underline="none" classes={{ root: 'link' }} color="#1177CC" href={"/S"} >Stats</Link>
              {
                paths.map((path, index) => {
                  let link = index === (paths.length - 1) ? '' : `/T/${path.endpoint}${window.location.search}`;
                  return (
                    <Link key={index} underline="none" className='link' color="#1177CC" href={link}>{path.label}</Link>
                  )
                })
              }
            </Breadcrumbs> */}
            <div className='col flex-full'>
              {props.children}
            </div>
          </>
          :
          <div className='col flex-full flex-ctr'>
            <CircularProgress />
          </div>
      }
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDefinedList: () => {
      dispatch(fetchTaskDepartment())
      dispatch(fetchTaskRepeationOccurList())
      dispatch(fetchTaskAccoutable())
      dispatch(fetchTaskAssignee())
      dispatch(fetchTaskSupervisor())
      dispatch(fetchDashBoard())
      dispatch(fetchMappedOrgs())
    },
  }
}
const mapStateToProps = (state) => {
  return {
    getSource: (id) => {
      return getSourceWithTaskById(state, id)
    },
    isListReady: isListReady(state),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TaskHomeScreen);
