import React, { useState } from 'react';
import { usePub } from "../Event";
import { useLocation } from "react-router-dom";
import './styles.scss'
import AppLogo from "../../assets/images/app_logo.png";
import CompanyLogoImg from "../../assets/images/company_logo.png";
import ProfilePic from "../../assets/images/profile_pic.png";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import AuditorIcon from "../../assets/images/auditor-icon.png";
import ArcIcon from "../../assets/images/arc-icon.png";
import ConsoleIcon from "../../assets/images/console-icon.png";
import ControllerIcon from "../../assets/images/controller-icon.png";
import DocumentorIcon from "../../assets/images/documenter-icon.png";
import EducatorIcon from "../../assets/images/educator-icon.png";
import InvestigatorIcon from "../../assets/images/investigator-icon.png";
import ContinuerIcon from "../../assets/images/continuer-icon.png";
import CentralIcon from "../../assets/images/central-icon.png";

const DOMAIN = process.env.REACT_APP_MAIN_DOMAIN;

// const NavItems = [
//   { link: '/D', label: 'Dashboard',  },
//   { link: '/T', label: 'Task', },
//   { link: '/S', label: 'Source' },
//   // { link: '/O', label: 'Setting' }
// ]
const NavItems = [
  { link: '/T', label: 'Tracker', },
  { link: '/S', label: 'Stats', },
]

const Modules = [
  { moduleId: "auditor", Component: AuditorIcon, label: 'Auditor' },
  { moduleId: "arc", Component: ArcIcon, label: 'Arc' },
  { moduleId: "console", Component: ConsoleIcon, label: 'Console' },
  { moduleId: 'controller', Component: ControllerIcon, label: 'Controller', isAdmin: true },
  { moduleId: 'documenter', Component: DocumentorIcon, label: 'Documenter' },
  { moduleId: 'educator', Component: EducatorIcon, label: 'Educator' },
  { moduleId: 'investigator', Component: InvestigatorIcon, label: 'Investigator' },
  { moduleId: 'continuer', Component: ContinuerIcon, label: 'Continuer' },
  { moduleId: 'central', Component: CentralIcon, label: 'Central' },
]

const NavItem = (props) => {
  const { pathname } = useLocation();
  const isActive = pathname === props.link;
  return (
    <a href={props.link} className={`item ${isActive ? 'active' : ''}`}>
      {props.label}
    </a>
  )
}

const CompanyLogo = (props) => {
  const { user } = props
  let [error, setError] = useState(false);
  return (
    <>
      {
        error ?
          <img src={CompanyLogoImg} alt='company Logo' className='company' />
          :
          <img src={'https:' + (user.logo_url ? user.logo_url : (user.ent_org && user.ent_org.logo_url ? user.ent_org.logo_url : ''))} alt='company Logo' className='company' onError={() => setError(true)} />
      }
    </>
  )
}
export const getModuleLink = (mod, user) => {
  const sessionToken = user.session_token;
  if (mod === 'localhost') {
    return `http://localhost:3000/login?sessionToken=${sessionToken}`
  }
  return `https://${mod}.${DOMAIN}/login?sessionToken=${sessionToken}`
}
export const AppNavBar = (props) => {
  const publish = usePub();
  const { isAdmin, isArchitect, currentModule } = props
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const notFound = NavItems.findIndex((_) => pathname.indexOf(_.link) === 0) === -1;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logout = (e) => {
    e.preventDefault();
    publish('SESSION_LOGOUT');
  }
  const getSubscribedModules = () => {
    let modules = Array.from(Modules);
    modules = modules.filter((mod) => {
      return mod.isAdmin ? (isAdmin || isArchitect) : mod.moduleId !== currentModule;
    })
    modules.sort((modA, modB) => {
      let moduleIdA = String(modA.moduleId).toLowerCase(),
        moduleIdB = String(modB.moduleId).toLowerCase();
      return moduleIdA.localeCompare(moduleIdB)
    })
    return modules;
  }
  return (
    <nav className='row navbar'>
      <a href="/"><img src={AppLogo} alt='App Logo' className='arc-logo' /></a>
      {
        !notFound &&
        <div className='row flex-full'>
          <div className='row space-btn items'>
            {
              NavItems.map((_item) => {
                return (
                  <NavItem key={_item.label} {..._item} />
                )
              })
            }
          </div>
          <div className='row space-end account-options'>
            <div className='account-icon' onClick={handleClick} >
              <img src={ProfilePic} alt='profile' height={50} />
              <ArrowDropDownIcon className="dropdown" fontSize='large' sx={{ color: '#073C3C', height: '2em' }} />
            </div>
            <CompanyLogo user={props.user} />
          </div>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <div className='col app-menu'>
              <div className='row profile-row'>
                <img src={ProfilePic} className='menu-pic' alt='profile' height={50} />
                <div className='col flex-full'>
                  <div className='row'>
                    <ul className='menu-profile'>
                      <li className='menu-name'>{props.user.firstname} {props.user.lastname}</li>
                      <li className='menu-email'>{props.user.email}</li>
                    </ul>
                  </div>
                  <div className='row menu-options-row'>
                    <a className='menu-options' href={`${getModuleLink('console', props.user)}&redirect=profile`}>
                      <span className='h8 profile'>Profile</span>
                    </a>
                    <a className='menu-options' onClick={logout}>
                      <span className='h8 logout'>Sign Out</span>
                    </a>
                  </div>
                </div>
              </div>
              {
                getSubscribedModules().map(({ label, Component, moduleId }) => {
                  return (
                    <a className='row v-ctr h7 menu-entry link c00085' rel="noreferrer" key={moduleId} href={getModuleLink(moduleId, props.user)}>
                      <img className="mod-logo" src={Component} />
                      <span>{label}</span>
                    </a>
                  )
                })
              }
            </div>
          </Menu>
        </div>
      }
    </nav>
  )
}